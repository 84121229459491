import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { volunteerActions } from '@store/slices/volunteer/slice';

import { HowToHelpType } from '@shared/enums';
import { ChipChoice } from '@shared/ui/chips/ChipChoice';
import { isOnlineMode } from '@shared/utils/applications';

import { useVolunteerMode } from '../modals/hooks/useVolunteerMode';

import { useChipLists } from './useChipLists';

export const VolunteerHowToHelpOnlineChoose = () => {
  const dispatch = useDispatch();
  const { chipList, offlineChipList } = useChipLists();

  const [mode] = useVolunteerMode();

  const handleChangeHowToHelp = useCallback((eventKey: HowToHelpType) => {
    dispatch(volunteerActions.setCategoryFilter(eventKey));
  }, []);

  return (
    <div className="flex flex-nowrap md:flex-wrap md:gap-y-3 gap-x-3 scrollable overflow-x-auto xl:overflow-x-visible">
      {(isOnlineMode(mode) ? chipList : offlineChipList).map((item) => (
        <ChipChoice
          key={item.eventKey}
          label={item.label}
          emoji={item.emoji}
          icon={item.icon}
          isActive={item.isActive}
          eventKey={item.eventKey}
          onClick={handleChangeHowToHelp}
          className="flex-shrink-0"
        />
      ))}
    </div>
  );
};
