import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

import { CabinetFilters } from '@widgets/cabinet/cabinetFilters/CabinetFilters';
import { CabinetVolunteerApplications } from '@widgets/cabinet/volunteer/content/CabinetVolunteerApplications';
import { VolunteerWhoNeedHelpFilters } from '@widgets/cabinet/volunteer/filters/VolunteerWhoNeedHelpFilters';
import { CabinetVolunteerTabs } from '@widgets/cabinet/volunteer/tabs';

import { CabinetModals } from '@widgets/cabinet/volunteer/CabinetModals';
import { ApplicantCategoriesFilters } from '@widgets/cabinet/volunteer/filters/ApplicantCategoriesFilters';
import { CabinetVolunteerLocationFilters } from '@widgets/cabinet/volunteer/filters/CabinetVolunteerLocationFilters';
import { HandToHandNeedCategoriesFilters } from '@widgets/cabinet/volunteer/filters/HandToHandNeedCategoriesFilters';
import { HandToHandNeedsFilters } from '@widgets/cabinet/volunteer/filters/HandToHandNeedsFilters';
import { VolunteerHowToHelpOnlineChoose } from '@widgets/cabinet/volunteer/filters/VolunteerHowToHelpOnlineChoose';
import { useFiltersOptions } from '@widgets/cabinet/volunteer/modals/hooks/useFiltersOptions';
import { useSyncVolunteerStateQueryParams } from '@widgets/cabinet/volunteer/modals/hooks/useSyncVolunteerStateQueryParams';
import { useFBTrackViewContent } from '@widgets/cabinet/volunteer/useFBTrackViewContent';
import { VolunteerHowToHelpChoose } from '../../widgets/cabinet/volunteer/filters/VolunteerHowToHelpChoose';
import { AdditionalFilters } from '@widgets/cabinet/volunteer/filters/AdditionalFilters';
import { LegionBadge } from '@widgets/badges/legion-badge';
import { CertificatesButton } from '@widgets/cabinet/volunteer/buttons/CertificatesButton';
import { BulkPurchasesFilters } from '@widgets/cabinet/volunteer/filters/BulkPurchasesFilters';
import { useUser } from '@shared/hooks/useUser';

import { useProfile } from '@shared/hooks/useProfile';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ShopFilters } from '@widgets/cabinet/volunteer/filters/ShopFilters';

const useComponentState = () => {
  const { t } = useTranslation();

  const [user] = useUser();
  const profile = useProfile();

  const welcomeText = `${t('go-ahead-help')}!`;
  const welcomeIcon = useGetIconOptimized('biceps');

  return {
    doneApplications: profile?.doneApplications || 0,
    isUserMember: user?.isMember || false,

    welcomeIcon,
    welcomeText,
  };
};

const CabinetVoluneerPage = () => {
  const filtersOptions = useFiltersOptions();
  useFBTrackViewContent();

  useSyncVolunteerStateQueryParams();

  const { doneApplications, isUserMember, welcomeIcon, welcomeText } = useComponentState();

  return (
    <>
      <div className="max-w-[1400px] mx-auto px-4 pt-6 pb-8 lg:pt-12 lg:pb-28">
        <h1 className="text-md md:text-xl mb-4 xl:mb-6">
          {welcomeText}
          <GatsbyImage image={getImage(welcomeIcon)!} alt="Flexed Biceps" className="ml-2 md:ml-3 w-[25px] h-[25px]" />
        </h1>

        <div className="xl:flex">
          <div>
            <div className="max-w-[400px]">
              {isUserMember && doneApplications >= 5 && (
                <div className="mb-3 lg:mb-6">
                  <LegionBadge progress={doneApplications} />
                </div>
              )}

              <BulkPurchasesFilters className="mb-3 lg:mb-6" />
            </div>

            <VolunteerHowToHelpChoose />

            <div className="flex flex-col mt-6 xl:flex-row xl:mt-7 mb-5 lg:mb-10">
              <div className="xl:min-w-[420px]">
                <VolunteerHowToHelpOnlineChoose />
              </div>
            </div>

            <CabinetFilters before={<ShopFilters />}>
              <VolunteerWhoNeedHelpFilters />
              <CabinetVolunteerLocationFilters
                regions={filtersOptions.regions}
                localities={filtersOptions.localities}
              />
              <ApplicantCategoriesFilters items={filtersOptions.applicantCategories} />
              <HandToHandNeedCategoriesFilters items={filtersOptions.categories} />
              <HandToHandNeedsFilters items={filtersOptions.categoryItems} />
              <AdditionalFilters />
            </CabinetFilters>
          </div>

          <div className="w-full">
            <div className="flex flex-col md:flex-row items-center w-full mb-5 lg:mb-10">
              <CabinetVolunteerTabs
                className={clsx('xs:justify-center', isUserMember && 'mx-auto md:ml-0', !isUserMember && 'mx-auto')}
              />
              {isUserMember && <CertificatesButton className="xl:ml-[60px] xxl:ml-[100px] md:pb-[20px]" />}
            </div>
            <CabinetVolunteerApplications />
          </div>
        </div>
      </div>
      <CabinetModals />
    </>
  );
};

export default CabinetVoluneerPage;
